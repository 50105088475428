import clsx from 'clsx';
import {
    CheckPrimarySolid,
    ChevronLeftIcon,
    CreditCardGrayIcon,
    CreditCardPrimaryIcon,
    CreditCardSecondaryIcon,
} from './Icons';

type PaymentMethodButtonProps = {
    name?: string;
    fourDigit?: number;
    month?: number;
    year?: number;
    cardType?: string;
    selected?: boolean;
    onClick(): void;
};

function getExpiredCard(monthCard?: number, yearCard?: number) {
    const month = new Date().getMonth() + 1;
    const year = new Date().getFullYear();

    if (monthCard! <= month && yearCard! <= year) {
        return true;
    }
    return false;
}

function getIcon(selected?: boolean) {
    if (selected) {
        return <CreditCardPrimaryIcon />;
    }
    return <CreditCardSecondaryIcon />;
}

export default function PaymentMethodButton({
    name,
    fourDigit,
    month,
    year,
    cardType,
    selected,
    onClick,
}: PaymentMethodButtonProps) {
    return (
        <button
            type="button"
            onClick={onClick}
            className={clsx(
                'max-h-[72px] min-h-[72px] w-full border px-6',
                selected ? 'border-primary' : 'border-[#727272]'
            )}
            disabled={getExpiredCard(month, year)}
        >
            {cardType ? (
                <div className="flex justify-between">
                    <div className="mt-auto mb-auto flex">
                        <div className="m-auto">
                            {getExpiredCard(month, year) ? (
                                <CreditCardGrayIcon />
                            ) : (
                                <div>{getIcon(selected)} </div>
                            )}
                        </div>
                        <div className="pl-5 text-left">
                            <div>
                                <span className="text-base font-bold capitalize">
                                    {cardType}
                                </span>
                                <span className="text-base">
                                    **** **** **** {fourDigit}
                                </span>
                            </div>
                            <div>
                                <span className="text-sm font-normal">
                                    Expires {month}/{year! - 2000}
                                </span>
                            </div>
                        </div>
                    </div>
                    {selected && (
                        <div className="mt-auto mb-auto">
                            <CheckPrimarySolid />
                        </div>
                    )}
                </div>
            ) : (
                <div className="flex justify-between">
                    <div>{name}</div>
                    <div className="mt-auto mb-auto rotate-180">
                        <ChevronLeftIcon />
                    </div>
                </div>
            )}
        </button>
    );
}
