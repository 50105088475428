import { useEffect } from 'react';
import { Outlet, useLocation, useParams } from 'react-router-dom';
import GetStartedFooter from '@/components/common/GetStartedFooter';
import GetStartedHeader from '@/components/common/GetStartedHeader';
import { useImpactIdentify } from '@/services/dataLayer';
import { useUser } from '@/store/user/userSlice';

export default function GetStartedLayout() {
    const [user] = useUser();
    const { partners } = useParams();
    const location = useLocation();
    const impactIdentify = useImpactIdentify;

    useEffect(() => {
        impactIdentify(user.id, user.email);
    }, [impactIdentify, user, location]);

    return (
        <main className="flex min-h-full flex-col">
            <GetStartedHeader partners={partners} />
            <div className="flex-1">
                <Outlet />
            </div>
            <GetStartedFooter />
        </main>
    );
}
