import { useMutation, useQuery } from '@tanstack/react-query';
import { decamelize } from 'humps';
import client from './client';

export const getGiftCard = (data: any) =>
    client.get<any>(
        `/api/gift-cards/${data.activationCode ? data.activationCode : data}`
    );

export function useGetGiftCard() {
    return useMutation(async (payload: any) => {
        const { data } = await getGiftCard(payload);
        return data;
    });
}

export const activateGiftCard = (data: any) =>
    client.post<any>(
        window.Echo.socketId()
            ? `/api/gift-cards/${
                  data.activationCode ? data.activationCode : data
              }/activate`
            : `/api/socketless/gift-cards/${
                  data.activationCode ? data.activationCode : data
              }/activate`
    );

export function useActivateGiftCard() {
    return useMutation(async (payload: any) => {
        const { data } = await activateGiftCard(payload);
        return data;
    });
}

export const giftCardSettings = () => client.get('/api/gift-cards/settings');

export function useGiftCardSettings() {
    const query = useQuery(
        ['giftCardSettings'],
        async () => {
            const { data } = await giftCardSettings();
            return data;
        },
        {
            retry: false,
        }
    );
    const { data: giftCardSettingsResult, ...queryResult } = query;
    return { giftCardSettingsResult, ...queryResult };
}

export const giftCardCheckout = (data: any) =>
    client.post<any>(
        window.Echo.socketId()
            ? `/api/gift-cards/purchase`
            : `/api/socketless/gift-cards/purchase`,
        data,
        {
            transformRequest: [
                (d: any) => {
                    const payload: any = new FormData();
                    Object.entries(d).forEach(([key, value]) => {
                        if (key === 'number' && value) {
                            payload.append('card[number]', value);
                        } else if (key === 'month' && value) {
                            payload.append('card[month]', value);
                        } else if (key === 'year' && value) {
                            payload.append('card[year]', value);
                        } else if (key === 'cvc' && value) {
                            payload.append('card[cvc]', value);
                        } else if (key === 'sms' && value) {
                            payload.append(decamelize(key), +value);
                        } else if (key === 'newsletter' && value) {
                            payload.append(decamelize(key), +value);
                        } else if (key === 'senderName') {
                            payload.append('sender[name]', value);
                        } else if (key === 'senderEmail') {
                            payload.append('sender[email]', value);
                        } else if (key === 'receiverName') {
                            payload.append('receiver[primary][name]', value);
                        } else if (key === 'receiverEmail') {
                            payload.append('receiver[primary][email]', value);
                        } else if (key === 'receiverMessage' && value !== '') {
                            payload.append('receiver[primary][message]', value);
                        } else if (key === 'receiverDeliveryDate') {
                            payload.append(
                                'receiver[primary][delivery_date]',
                                value
                            );
                        } else if (
                            key === 'bonusReceiverName' &&
                            value !== ''
                        ) {
                            payload.append('receiver[bonus][name]', value);
                        } else if (
                            key === 'bonusReceiverEmail' &&
                            value !== ''
                        ) {
                            payload.append('receiver[bonus][email]', value);
                        } else if (
                            key === 'bonusReceiverMessage' &&
                            value !== ''
                        ) {
                            payload.append('receiver[bonus][message]', value);
                        } else if (
                            key === 'bonusReceiverDeliveryDate' &&
                            value !== ''
                        ) {
                            payload.append(
                                'receiver[bonus][delivery_date]',
                                value
                            );
                        } else if (
                            value !== undefined &&
                            value !== false &&
                            value !== ''
                        ) {
                            payload.append(decamelize(key), value);
                        }
                    });
                    return payload;
                },
            ],
        }
    );

export function useGiftCardCheckout() {
    return useMutation(async (payload: any) => {
        const { data } = await giftCardCheckout(payload);
        return data;
    });
}
