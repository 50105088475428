import * as RadioGroup from '@radix-ui/react-radio-group';
import clsx from 'clsx';
import { Field, FieldProps } from 'formik';
import Button from '@/components/common/Button';
import BoxRadio from '@/components/inputs/BoxRadio';
import InputGroup from '@/components/inputs/InputGroup';
import TextInput from '@/components/inputs/TextInput';
import ClaimOfferFormModel from '@/models/ClaimOfferFormModel';
import { makeDot } from '@/types/Path';
import Form from '../Form';

const dot = makeDot<ClaimOfferFormModel>();

type ClaimOfferFormProps = {
    selectedCode?: any;
    setSelectedCode(value: any): void;
    offers: any;
};

export default function ClaimOfferForm({
    selectedCode,
    setSelectedCode,
    offers,
}: ClaimOfferFormProps) {
    return (
        <Form>
            <Field name={dot('code')}>
                {({ field, form }: FieldProps) => (
                    <RadioGroup.Root
                        {...field}
                        value={selectedCode}
                        onValueChange={(value: any) => {
                            form.setFieldValue('code', value);
                            setSelectedCode(value);
                        }}
                        className={clsx(
                            'relative mx-auto mb-12 mt-4 flex w-full flex-col items-center justify-center sm:space-y-4 md:mb-0 md:flex-row md:space-x-4 md:space-y-0 md:items-stretch',
                            offers?.length === 3 &&
                            'md:w-[calc(100%_-_20rem)] md:justify-start md:overflow-auto md:p-2 lg:w-[calc(100%_-_10rem)] xl:w-full',
                            offers?.length >= 4 &&
                            'md:w-[calc(100%_-_40rem)] md:justify-start md:overflow-auto md:p-2 lg:w-[calc(100%_-_25rem)] xl:w-[calc(100%_-_10rem)]'
                        )}
                    >
                        {offers?.map((e: any) => (
                            <BoxRadio
                                key={e.code}
                                heading={e.title}
                                description={e.content}
                                value={e.code}
                                image={e.thumbnail}
                                smaller
                                className="md:min-w-[320px]"
                            />
                        ))}
                    </RadioGroup.Root>
                )
                }
            </Field >
            <div className="m-auto mt-6 w-11/12 max-w-md">
                <div className="mb-2 flex flex-col space-y-8">
                    <InputGroup name={dot('email')}>
                        <TextInput
                            placeholder="Enter your email address"
                            type="email"
                            autoComplete="email"
                        />
                    </InputGroup>
                </div>
                <div className="flex flex-col items-center justify-between sm:flex-row sm:space-x-4">
                    <div className="order-first w-full">
                        <Button
                            type="submit"
                            size="sm"
                            className="flex w-full"
                            full
                        >
                            Activate discount
                        </Button>
                    </div>
                </div>
                <div className="mt-2 text-xs font-medium">
                    By providing my email address, I agree to receive exclusive
                    offers and marketing emails from ButcherCrowd. You can
                    unsubscribe at any time.
                </div>
            </div>
        </Form >
    );
}
