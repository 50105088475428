import clsx from 'clsx';

type SectionTitleProps = {
    title?: string;
    subtitle?: string;
    withLine?: boolean;
    bold?: boolean;
    uppercase?: boolean;
};

export default function SectionTitle({
    title,
    subtitle,
    withLine,
    bold,
    uppercase,
}: SectionTitleProps) {
    return (
        <div className="container mx-auto mb-6">
            <div className="flex flex-col items-center justify-center space-x-0 px-8 lg:flex-row lg:space-x-16">
                {withLine && (
                    <div className="hidden h-[5px] flex-1 bg-secondary lg:block" />
                )}

                <div className="relative flex flex-col">
                    <div
                        className={clsx(
                            'relative z-10 text-center text-4xl font-medium sm:text-5xl',
                            withLine ? 'title-line font-bold' : '',
                            bold && 'font-bold',
                            uppercase && 'uppercase'
                        )}
                    >
                        {title}
                    </div>
                </div>
                {withLine && (
                    <div className="mt-4 h-[5px] w-16 bg-secondary lg:mt-0 lg:w-auto lg:flex-1" />
                )}
            </div>
            <div className="mx-auto max-w-[calc(100%_-_30px)] pt-4 text-center tracking-wide sm:max-w-[600px] lg:pt-3">
                {subtitle}
            </div>
        </div>
    );
}
