import clsx from 'clsx';
import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import Button from '@/components/common/Button';
import { ArrowRightIcon } from '@/components/common/Icons';
import { useActivateGiftCard } from '@/services/giftCardService';
import { setLoading } from '@/store/getStarted/getStartedLoadingSlice';
import { setData } from '@/store/giftCards/giftCardsDataSlice';
import { setGiftCardsError } from '@/store/giftCards/giftCardsErrorSlice';
import { useUser } from '@/store/user/userSlice';
import queryClient from '@/utils/queryClient';

type Props = {
    simpleStyle?: boolean;
};

export default function GiftCardCodeInput({ simpleStyle }: Props) {
    const [user] = useUser();
    const dispatch = useDispatch();
    const [code, setCode] = useState('');
    const {
        data: giftCardData,
        mutateAsync: activateGiftCard,
        isLoading: activateGiftCardIsFetching,
        error: activateGiftCardError,
        isSuccess: activateGiftCardSuccess,
    } = useActivateGiftCard();

    useEffect(() => {
        if (activateGiftCardIsFetching) {
            dispatch(setLoading(true));
        }
        if (activateGiftCardSuccess) {
            setCode('');
            if (!window.Echo.socketId() && giftCardData) {
                dispatch(setLoading(false));
                queryClient.setQueryData(['creditBalance'], {
                    balance: giftCardData.balance,
                });
                dispatch(
                    setData({
                        user: user.id,
                        code: giftCardData.code,
                        value: giftCardData.value,
                    })
                );
            }
        }
        if (activateGiftCardError) {
            dispatch(setGiftCardsError(true));
            dispatch(setLoading(false));
        }
    }, [
        user,
        activateGiftCardSuccess,
        activateGiftCardError,
        dispatch,
        activateGiftCardIsFetching,
        giftCardData,
    ]);

    return (
        <div>
            <div
                className={clsx(
                    'relative',
                    simpleStyle
                        ? 'flex w-full'
                        : 'rounded-lg border border-gray-200 bg-white py-3 px-3'
                )}
            >
                <div
                    className={clsx(
                        'relative mt-1',
                        simpleStyle && 'w-[calc(100%_-_38px)]'
                    )}
                >
                    <input
                        type="text"
                        placeholder="Enter gift card code"
                        className={clsx(
                            'form-input',
                            !simpleStyle && 'rounded'
                        )}
                        value={code?.toUpperCase()}
                        onChange={(event) => {
                            setCode(event.target.value);
                        }}
                    />
                </div>
                <Button
                    size="sm"
                    type="button"
                    disabled={code === ''}
                    onClick={() => {
                        activateGiftCard(code.replace(/\s+/g, ''));
                    }}
                    className={clsx(
                        'flex items-center justify-center',
                        simpleStyle
                            ? 'mt-1 h-[34px] w-[34px] !bg-dashboard !p-0 transition-colors hover:!bg-primary sm:h-[38px] sm:w-[38px]'
                            : 'absolute top-[16px] right-[12px] h-[34px] w-[70px] rounded !bg-dashboard !font-neue font-medium normal-case !tracking-normal sm:top-[13px] sm:my-1 sm:h-[36px]'
                    )}
                >
                    <ArrowRightIcon />
                </Button>
            </div>
        </div>
    );
}
