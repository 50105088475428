import { TestConfig } from 'yup';

export default function postcode(
    message: string
): TestConfig<string | undefined> {
    return {
        name: 'postCode',
        test(this, value?: string) {
            return !value || /^\d{3,4}$/.test(value);
        },
        message,
    };
}
