import { Slot } from '@radix-ui/react-slot';
import clsx from 'clsx';
import React from 'react';
import IconType from '@/types/IconType';

type Props = {
    asChild?: boolean;
    icon?: IconType;
};

const DefaultElement = 'a';

const LinkButton = React.forwardRef<
    React.ElementRef<typeof DefaultElement>,
    React.ComponentProps<typeof DefaultElement> & Props
>(({ asChild, children, className, icon: Icon, ...props }, forwardedRef) => {
    const Comp = asChild ? Slot : 'a';

    return (
        <Comp
            {...props}
            ref={forwardedRef}
            className={clsx(
                'uppercase hover:underline focus:outline-none focus-visible:underline focus-visible:ring focus-visible:ring-primary',
                className
            )}
        >
            {Icon ? (
                <Icon className="h-5 w-5" color="currentColor" />
            ) : (
                children
            )}
        </Comp>
    );
});

export default LinkButton;
