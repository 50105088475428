export default function getDiscountAmount(
    promotion: any,
    totalProductPrice: any,
    totalAddOnsPrice: any
) {
    if (
        (!promotion?.packages && !promotion?.products) ||
        (promotion?.packages && promotion?.products)
    ) {
        if (promotion?.type === 'value') return promotion?.value;
        if (promotion?.type === 'percentage')
            return (
                (totalProductPrice + totalAddOnsPrice) *
                (Number(promotion?.value) / 10000)
            );
        return (
            (totalProductPrice + totalAddOnsPrice) *
            (Number(promotion?.value) / 10000)
        );
    }
    if (promotion?.packages) {
        if (promotion?.type === 'value')
            return totalProductPrice > promotion?.value
                ? promotion?.value
                : totalProductPrice;
        if (promotion?.type === 'percentage')
            return totalProductPrice * (Number(promotion?.value) / 10000);
        return totalProductPrice * (Number(promotion?.value) / 10000);
    }
    if (promotion?.products) {
        if (promotion?.type === 'value')
            return totalAddOnsPrice > promotion?.value
                ? promotion?.value
                : totalAddOnsPrice;
        if (promotion?.type === 'percentage')
            return totalAddOnsPrice * (Number(promotion?.value) / 10000);
    }
    return null;
}
