import { Outlet } from 'react-router-dom';
import WebFooter from '@/components/common/WebFooter';
import WebHeader from '@/components/common/WebHeader';

export default function WebLayout() {
    return (
        <>
            <WebHeader />
            <main id="main">
                <Outlet />
            </main>
            <WebFooter />
        </>
    );
}
