import { useQuery } from '@tanstack/react-query';
import { useEffect } from 'react';
import { useCookies } from 'react-cookie';
import { useSearchParams } from 'react-router-dom';
import { modifySubscriptionOffer } from '@/services/deliveryZoneService';
import { getDiscountMethods } from '@/services/discountService';
import { useUser } from '@/store/user/userSlice';

export default function useQueryPromoManager() {
    const [searchParams] = useSearchParams();
    const [cookies, setCookie, removeCookie] = useCookies(['promo']);
    const [user] = useUser();

    if (user?.subscription?.id && user?.subscription?.code === cookies?.promo) {
        removeCookie('promo', {
            path: '/',
        });
    }

    const { data: discountData, status: discountStatus } = useQuery(
        ['discount', searchParams.get('promo')],
        () =>
            getDiscountMethods(
                searchParams.get('promo')!,
                user?.subscription?.id
            ),
        {
            enabled:
                searchParams.get('promo') !== null &&
                searchParams.get('promo') !== '',
            retry: false,
        }
    );

    useQuery(
        ['modifySubscriptionOffer', cookies.promo],
        () =>
            modifySubscriptionOffer(user?.subscription?.id, {
                code: cookies.promo,
            }),
        {
            enabled:
                cookies?.promo !== undefined &&
                cookies?.promo !== null &&
                user?.subscription?.id !== undefined &&
                user?.subscription?.code !== cookies?.promo,
            retry: false,
        }
    );

    useEffect(() => {
        if (discountStatus === 'success' && discountData?.data?.usable) {
            setCookie('promo', discountData?.data?.code?.toUpperCase(), {
                maxAge: 3600 * 24,
                path: '/',
            });
        }
    }, [discountStatus, discountData, searchParams, setCookie]);
}
