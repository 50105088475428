import { Listbox } from '@headlessui/react';
import { useFormikContext } from 'formik';
import { useEffect, useMemo } from 'react';
import useInitialDeliveryTimeIndex from '@/hooks/useInitialDeliveryTimeIndex';
import GetStartedFormModel from '@/models/GetStartedFormModel';
import { makeDot } from '@/types/Path';
import { getDateUtc, getTime } from '@/utils/luxonFormats';
import { ChevronDownIcon } from '../common/Icons';

const dot = makeDot<GetStartedFormModel>();

type DeliveryTimeSelectProps = {
    data?: any;
};

export default function DeliveryTimeSelect({ data }: DeliveryTimeSelectProps) {
    const formik = useFormikContext();
    const { shippingPostcode, deliveryScheduleId, shippingDateStartedAt } =
        formik.values as Partial<GetStartedFormModel>;
    const { setFieldValue, setFieldTouched } = formik;

    const deliveryTime = data?.data
        ?.filter((e: any) => e.date === shippingDateStartedAt)
        .reduce((acc: any, obj: any) => {
            if (!acc.some((item: any) => item.id === obj.id)) {
                acc.push(obj);
            }
            return acc;
        }, []);

    const initialValueIndex = useInitialDeliveryTimeIndex(deliveryTime);
    const initialValue = useMemo(
        () => deliveryTime?.[initialValueIndex],
        [deliveryTime, initialValueIndex]
    );
    const value: any = deliveryScheduleId;
    const placeholder = deliveryTime?.filter((e: any) => e.id === value);

    useEffect(() => {
        if (initialValue) {
            setFieldValue(dot('deliveryScheduleId'), initialValue.id);
            setFieldValue(
                dot('shippingStartedAt'),
                getDateUtc(`${initialValue.date}T${initialValue.start}Z`)
            );
        }
    }, [shippingPostcode, setFieldValue, initialValue, shippingDateStartedAt]);

    const handleChange = (key: any) => {
        setFieldValue(
            dot('shippingStartedAt'),
            getDateUtc(`${key.date}T${key.start}Z`)
        );
        setFieldValue(dot('deliveryScheduleId'), key.id);
    };

    return (
        <Listbox
            value={value?.id}
            onChange={handleChange}
            disabled={!deliveryTime?.length}
        >
            <Listbox.Button
                onBlur={() => setFieldTouched(dot('deliveryScheduleId'))}
                className="flex items-center justify-between w-full h-10 px-6 text-xs border border-secondary focus:outline-none focus-visible:ring focus-visible:ring-primary disabled:opacity-40 sm:text-base"
            >
                {placeholder && placeholder.length > 0
                    ? `
                    Between
                    ${getTime(
                        `${placeholder[0].date}T${placeholder[0].start}Z`
                    )}
                    and
                    ${getTime(`${placeholder[0].date}T${placeholder[0].end}Z`)}`
                    : 'Select a delivery time'}
                <ChevronDownIcon />
            </Listbox.Button>
            <Listbox.Options className="p-2 bg-white border select-none border-secondary shadow-block">
                {deliveryTime?.map((option: any) => (
                    <Listbox.Option
                        key={option?.id}
                        value={option}
                        className="flex items-center justify-between px-4 py-2 text-xs cursor-pointer focus:outline-none focus-visible:ring focus-visible:ring-primary highlighted:text-primary checked:font-semibold sm:text-base"
                    >
                        Between {getTime(`${option.date}T${option.start}Z`)} and{' '}
                        {getTime(`${option.date}T${option.end}Z`)}
                    </Listbox.Option>
                ))}
            </Listbox.Options>
        </Listbox>
    );
}
