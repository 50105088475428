import { useFormikContext } from 'formik';
import { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import CheckoutFormModel from '@/models/CheckoutFormModel';
import {
    resetItems,
    selectProducts,
    selectTotalCount,
    setItemCount,
} from '@/store/yourItems/yourItemsSlice';
import { makeDot } from '@/types/Path';
import PromotionSummaryItem from '../common/PromotionSummaryItem';

type Props = {
    size?: number;
    items?: any[];
    allItems?: any;
    postcode?: any;
};

const dot = makeDot<CheckoutFormModel>();

const availableProducts = (promotionItems: any) =>
    promotionItems.some(
        (e: any) =>
            e.choice === true &&
            (e.order === 1 || e.order === null) &&
            (e.minimum === null || e.minimum === 1)
    );

const findNextPromoOrderNumber = (promotionItems: any) => {
    let minimum: number | null = null;
    promotionItems.forEach((product: any) => {
        if (minimum === null || product.minimum < minimum) {
            minimum = product.minimum;
        }
    });
    return minimum;
};

export default function CheckoutSummaryPromotionCard({
    size,
    items,
    allItems,
    postcode,
}: Props) {
    const dispatch = useDispatch();
    const formik = useFormikContext();
    const { setFieldValue } = formik;
    const maxCount = size;
    const totalCount = useSelector(selectTotalCount);
    const products = useSelector(selectProducts);
    const { values } = useFormikContext<CheckoutFormModel>();
    useEffect(() => {
        dispatch(resetItems());
        if (allItems) {
            Object.entries(allItems).forEach((e: any) => {
                if (e[1].choice && (e[1].order === null || e[1].order === 1)) {
                    dispatch(
                        setItemCount({
                            id: e[0],
                            count: 0,
                        })
                    );
                }
            });
        }
    }, [allItems, dispatch]);

    useEffect(() => {
        if (products?.length > 0) {
            if (maxCount === totalCount) {
                setFieldValue(dot('choices'), products);
            } else {
                delete values.choices;
            }
        }
    }, [setFieldValue, products, maxCount, totalCount, values.choices]);

    return (
        <div>
            {availableProducts(items) ? (
                <>
                    <span className="text-base font-medium">
                        Choose your free product{maxCount! > 1 ? 's' : ''}
                    </span>
                    {postcode?.startsWith('7') ? (
                        <div>
                            {items && items?.[0] && (
                                <div className="flex max-h-[calc(100vh_-_335px)] flex-1 flex-col overflow-auto bg-white">
                                    {items?.map((item: any) => {
                                        if (
                                            item &&
                                            item.choice &&
                                            !item?.product?.name
                                                .toLowerCase()
                                                .includes('salmon') &&
                                            (item.order === null ||
                                                item.order === 1) &&
                                            (item.minimum === null ||
                                                item.minimum === 1)
                                        )
                                            return (
                                                <PromotionSummaryItem
                                                    key={item.id}
                                                    itemId={item.id}
                                                    {...item.product}
                                                    maxCount={maxCount || 1000}
                                                    maxSelection={item.maximum}
                                                    quantity={item.quantity}
                                                />
                                            );
                                        return null;
                                    })}
                                </div>
                            )}
                        </div>
                    ) : (
                        <div>
                            {items && items?.[0] && (
                                <div className="flex max-h-[calc(100vh_-_335px)] flex-1 flex-col overflow-auto bg-white">
                                    {items?.map((item: any) => {
                                        if (
                                            item &&
                                            item.choice &&
                                            (item.order === null ||
                                                item.order === 1) &&
                                            (item.minimum === null ||
                                                item.minimum === 1)
                                        )
                                            return (
                                                <PromotionSummaryItem
                                                    key={item.id}
                                                    itemId={item.id}
                                                    {...item.product}
                                                    maxCount={maxCount || 1000}
                                                    maxSelection={item.maximum}
                                                    quantity={item.quantity}
                                                />
                                            );
                                        return null;
                                    })}
                                </div>
                            )}
                        </div>
                    )}
                    <div className="flex justify-end bg-white">
                        <div className="whitespace-nowrap text-3xl font-bold">
                            {totalCount} / {maxCount}
                        </div>
                    </div>
                </>
            ) : (
                <span className="text-xs">
                    {maxCount} choice{maxCount! > 1 ? 's' : ''} starting from
                    order #{findNextPromoOrderNumber(items)}
                </span>
            )}
        </div>
    );
}
