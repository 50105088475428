import { useQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from '@/components/common/Button';
import ModalClaimOffer from '@/components/modals/ModalClaimOffer';
import { getDiscountMethods } from '@/services/discountService';
import { selectCurrentStep } from '@/store/getStarted/getStartedSlice';
import { useUser } from '@/store/user/userSlice';

type Props = {
    offers: any;
    coupon: string;
    promoApplyed?: boolean;
    autoOpenModal?: boolean;
};

function PromoBanner({ offers, coupon, promoApplyed, autoOpenModal }: Props) {
    const [user] = useUser();
    const promoMessage = 'Select Your Free Welcome Gift';
    const currentStep = useSelector(selectCurrentStep);
    const [openModal, setOpenModal] = useState(false);
    const [availablePromotion, setAvailablePromotion] = useState(true);
    const { data: discountData } = useQuery(
        ['discount', user?.subscription?.code],
        () =>
            getDiscountMethods(
                user?.subscription?.code!,
                user?.subscription?.id!
            ),
        {
            enabled:
                user?.subscription?.postcode?.startsWith('7') === true &&
                user?.subscription?.code !== undefined,
            retry: false,
        }
    );

    useEffect(() => {
        if (
            user?.subscription?.postcode?.startsWith('7') &&
            discountData?.data?.items.some((item: any) =>
                item.product.name.toLowerCase().includes('salmon')
            ) &&
            (discountData?.data.chooseItems === 0 ||
                discountData?.data.chooseItems === null)
        ) {
            setAvailablePromotion(false);
        }
    }, [discountData, user?.subscription?.postcode]);

    useEffect(() => {
        if (!promoApplyed && autoOpenModal && currentStep?.index === 0) {
            setOpenModal(true);
        }
    }, [promoApplyed, autoOpenModal, currentStep]);

    return (
        <div className="flex min-h-[3rem] flex-col items-center justify-center bg-primary py-2 px-2 text-center text-white md:flex-row">
            {promoApplyed && (
                <div className="flex items-center">
                    <p className="text-xs font-medium uppercase sm:text-sm lg:text-base">
                        {/* eslint-disable-next-line no-nested-ternary */}
                        {coupon
                            ? availablePromotion
                                ? `${coupon} promo applied -  Make order & proceed to checkout`
                                : `${coupon} -  This promotion is not applicable to Tasmania customers due quarantine restrictions with our wild salmon.`
                            : availablePromotion
                            ? 'Promo applied -  Make order & proceed to checkouts'
                            : 'This promotion is not applicable to Tasmania customers due quarantine restrictions with our wild salmon.'}
                    </p>
                </div>
            )}
            <div className="flex w-full flex-col items-center space-y-1 sm:w-auto sm:flex-row sm:space-y-0">
                {!promoApplyed && (
                    <div>
                        <p className="text-xs font-medium uppercase sm:text-sm lg:text-base">
                            {promoMessage}
                        </p>
                    </div>
                )}
                {currentStep.index === 0 &&
                    (offers && offers?.length > 1 ||
                        user?.subscription?.code !== offers?.[0]?.code) && (
                        <Button
                            asChild
                            white
                            size="xs"
                            className="mt-1 w-full cursor-pointer justify-center sm:ml-4 sm:w-auto md:mt-0"
                            onClick={() => setOpenModal(true)}
                        >
                            <span>
                                {promoApplyed ? 'Change offer' : 'Claim offer'}
                            </span>
                        </Button>
                    )}
            </div>
            <ModalClaimOffer
                offers={offers}
                title="Choose your offer"
                open={openModal}
                onClose={setOpenModal}
            />
        </div>
    );
}

export default PromoBanner;
