import * as Sentry from '@sentry/react';
import clsx from 'clsx';
import React, { useEffect, useMemo } from 'react';
import { useCookies } from 'react-cookie';
import { useSelector } from 'react-redux';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import useQueryPromoManager from '@/hooks/useQueryPromoManager';
import { useOffers } from '@/services/authService';
import {
    selectCurrentStep,
    selectPartnersSteps,
    selectSteps,
} from '@/store/getStarted/getStartedSlice';
import { useUser } from '@/store/user/userSlice';
import Navigation from '../navigation/Navigation';
import { StepsLine } from './Icons';
import PromoBanner from './PromoBanner';

const Logo = `${window.cdn}logo-2.png`;

type StepProps = {
    index: number;
    label: string;
    to?: string;
};

type Props = {
    partners?: any;
};

function Step({ index, label, to }: StepProps) {
    const { partners } = useParams();
    const [params] = useSearchParams();
    const { index: currentIndex } = useSelector(selectCurrentStep);

    const resolvedTo = useMemo(() => {
        if (!to) return undefined;
        return to.replace(
            /\$([a-z_][a-z0-9_]*)/gi,
            (_str, variable) => params.get(variable) || ''
        );
    }, [to, params]);

    const canNavigate = resolvedTo && index < currentIndex;
    const LinkElement = canNavigate ? Link : 'div';
    return (
        <li className="relative flex-shrink-0 text-center">
            <LinkElement
                className="group"
                to={partners ? `/${partners}/${resolvedTo!}` : resolvedTo!}
            >
                <span className="flex items-center justify-center text-sm font-medium text-primary">
                    <span
                        className={clsx(
                            'mx-3 mb-6 flex h-8 w-8 flex-shrink-0 items-center justify-center rounded-full font-agrandir text-xs font-bold transition-colors md:h-10 md:w-10 md:text-sm',
                            index <= currentIndex
                                ? 'bg-primary text-white'
                                : 'border border-primary bg-white',
                            index < currentIndex
                                ? 'group-hover:bg-darkenPrimary'
                                : ''
                        )}
                    >
                        {index + 1}
                    </span>
                </span>
                <span className="absolute top-9 -left-4 -right-4 text-[10px] font-medium uppercase text-gray-900 md:top-12 md:tracking-wide">
                    {label}
                </span>
            </LinkElement>
        </li>
    );
}

export default function GetStartedHeader({ partners }: Props) {
    useQueryPromoManager();
    const steps = useSelector(partners ? selectPartnersSteps : selectSteps);
    const [user] = useUser();
    const [cookies] = useCookies(['promo']);
    const { offersResult } = useOffers();

    const coupon = cookies?.promo || user?.subscription?.code;

    const couponIsApplyed = coupon && coupon !== null;

    useEffect(() => {
        if (user) {
            Sentry.setUser({ email: user.email });
        }
    }, [user]);

    return (
        <>
            {!partners && !user?.ordered && offersResult?.length > 0 && (
                <PromoBanner
                    coupon={coupon}
                    promoApplyed={couponIsApplyed}
                    offers={offersResult}
                    autoOpenModal
                />
            )}
            <div className="border-b-4 border-black">
                <div className="mx-auto flex h-[147px] flex-col items-center justify-center md:h-[112px] md:max-w-[calc(100%_-_60px)] md:flex-row md:justify-between lg:h-[132px]">
                    <div className="mb-4 md:mb-0">
                        <Navigation.Link
                            href={partners ? `/partners/${partners}` : '/'}
                            className="inline-block focus:outline-none focus-visible:ring focus-visible:ring-primary"
                        >
                            <img
                                src={Logo}
                                alt="ButcherCrowd"
                                className="mx-auto h-auto w-[154px] md:w-[185px] lg:mx-0 lg:w-[244px]"
                            />
                        </Navigation.Link>
                    </div>
                    <nav>
                        <ol className="flex flex-wrap items-center justify-center">
                            {steps.map((e) => (
                                <React.Fragment key={e.label}>
                                    <Step {...e} />
                                    {e.index !== steps.length - 1 && (
                                        <li
                                            className="flex items-center"
                                            aria-hidden="true"
                                        >
                                            <StepsLine className="mx-auto -mt-6 h-1 w-2 text-primary xs:w-6 sm:w-12 md:w-10 lg:w-20 xl:w-24" />
                                        </li>
                                    )}
                                </React.Fragment>
                            ))}
                        </ol>
                    </nav>
                </div>
            </div>
        </>
    );
}
