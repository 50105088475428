import { InferType, number, object, string } from 'yup';

export const boxTypeFormSchema = object({
    root: string(),
    pivot: string(),
    package: string(),
    frequency: number(),
});

type ModifyBoxTypeFormModel = InferType<typeof boxTypeFormSchema>;

export default ModifyBoxTypeFormModel;
