import { InferType, object, string } from 'yup';

export const claimOfferFormSchema = object({
    email: string().label('Email address').email().required(),
    code: string().label('Code').required(),
});

type ClaimOfferFormModel = InferType<typeof claimOfferFormSchema>;

export default ClaimOfferFormModel;
