import { useQuery } from '@tanstack/react-query';
import clsx from 'clsx';
import { useFormikContext } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { Spring, animated } from 'react-spring';
import useWindowDimensions from '@/hooks/useWindowDimensions';
import CheckoutFormModel from '@/models/CheckoutFormModel';
import { getDiscountMethods } from '@/services/discountService';
import { useCreditBalance } from '@/services/profileService';
import { useUser } from '@/store/user/userSlice';
import { makeDot } from '@/types/Path';
import { shortDate } from '@/utils/dateFormats';
import formatDollarAmount from '@/utils/formatDollarAmount';
import getDiscountAmount from '@/utils/promoAmount';
import { ChevronDownIcon } from '../common/Icons';
import DashboardSelect from '../inputs/DashboardSelect';
import GiftCardCodeInput from '../inputs/GiftCardCodeInput';
import InputGroup from '../inputs/InputGroup';
import PromoCodeInput from '../inputs/PromoCodeInput';
import BillingDetailsCard from './BillingDetailsCard';
import BillingProductListingCard from './BillingProductListingCard';
import CheckoutSummaryPromotionCard from './CheckoutSummaryPromotionCard';

type Props = {
    subscriptionId?: any;
    packageId?: any;
    startedAt: any;
    frequency?: any;
    setFrequency?: any;
    discountCode?: any;
    setDiscountCode?: any;
    giftCardsData?: any;
    deliveryFrequencyResult?: any;
    packageInfo?: any;
    products?: any;
    addOns?: any;
    postcode?: any;
    partners?: any;
};

const dot = makeDot<CheckoutFormModel>();

export default function CheckoutSummaryCard({
    subscriptionId,
    packageId,
    startedAt,
    frequency,
    setFrequency,
    discountCode,
    setDiscountCode,
    giftCardsData,
    deliveryFrequencyResult,
    packageInfo,
    products,
    addOns,
    postcode,
    partners,
}: Props) {
    const [user] = useUser();
    const [expanded, setExpanded] = useState(false);
    const { height: windowHeight } = useWindowDimensions();
    const { data: discountData, error: discountError } = useQuery(
        ['discount', discountCode],
        () => getDiscountMethods(discountCode!, subscriptionId!),
        {
            enabled: discountCode !== '' && discountCode !== undefined,
            retry: false,
        }
    );

    const { errors: checkoutError, isSubmitting } =
        useFormikContext<CheckoutFormModel>();
    const { creditBalanceResult } = useCreditBalance(
        !!(subscriptionId && packageId)
    );
    const promotion = discountData?.data;

    const totalProductPrice = () => {
        if (packageInfo?.price !== 0) {
            return packageInfo?.price;
        }
        let totalCost = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < products?.length; i++) {
            totalCost += products[i].price * products[i].quantity;
        }
        return totalCost;
    };

    const totalAddOnsPrice = () => {
        let totalCost = 0;
        // eslint-disable-next-line no-plusplus
        for (let i = 0; i < addOns?.length; i++) {
            totalCost += addOns[i].price * addOns[i].quantity;
        }
        return totalCost;
    };

    const getTotalPrice = () => {
        if (promotion?.usable && promotion?.values?.length > 0) {
            return (
                totalProductPrice() +
                totalAddOnsPrice() -
                getDiscountAmount(
                    promotion?.values?.[0],
                    totalProductPrice(),
                    totalAddOnsPrice()
                ) -
                (partners ? partners.value : 0)
            );
        }
        return (
            totalProductPrice() +
            totalAddOnsPrice() -
            (partners ? partners.value : 0)
        );
    };

    const itemsById = useMemo(
        () =>
            promotion?.items?.reduce(
                (obj: any, item: any) => ({ ...obj, [item.id]: item }),
                {} as { [id: number]: any }
            ),
        [promotion?.items]
    );

    useEffect(() => {
        if (checkoutError?.choices && isSubmitting) {
            setExpanded(true);
        }
    }, [checkoutError, isSubmitting]);

    return (
        <>
            {/* Mobile summary card */}
            <aside className="fixed right-2 left-2 bottom-[-2px] flex flex-col border border-secondary bg-white p-4 shadow-block lg:hidden">
                <button
                    className="flex h-[80px] items-center justify-between"
                    type="button"
                    onClick={() => setExpanded((s) => !s)}
                >
                    <div className="mb-4">
                        <div className="text-3xl font-bold uppercase">
                            Your Box
                        </div>
                        <div className="mt-2 text-left text-sm font-bold uppercase">
                            &nbsp;View here
                        </div>
                    </div>
                    <ChevronDownIcon
                        className={clsx(
                            'mb-14 h-5 w-8',
                            !expanded && 'rotate-180'
                        )}
                    />
                </button>
                <div className="pt-2">
                    <div
                        className="inset-0 flex items-center"
                        aria-hidden="true"
                    >
                        <div className="w-full border-t-4 border-black" />
                    </div>
                </div>
                <Spring
                    to={{
                        height: expanded ? windowHeight - 118 : 0,
                        opacity: expanded ? 1 : 0,
                    }}
                >
                    {({ height, opacity }: any) => (
                        <animated.div
                            style={{
                                height,
                                opacity,
                            }}
                            className="flex min-h-0 flex-col overflow-auto "
                        >
                            <div className="space-y-4 pt-2">
                                <div>
                                    <div className="text-[14px] font-medium">
                                        {packageInfo?.[0]}
                                        Delivered on
                                    </div>
                                    {startedAt && (
                                        <DashboardSelect
                                            placeholder={shortDate
                                                .format(new Date(startedAt))
                                                .replace(',', '')}
                                            onChange={() => {}}
                                            disabled
                                        />
                                    )}
                                </div>
                                {deliveryFrequencyResult && (
                                    <div>
                                        <div className="text-[14px] font-medium">
                                            Delivery Frequency
                                        </div>
                                        <DashboardSelect
                                            value={`${frequency}`}
                                            onChange={(value) => {
                                                setFrequency(Number(value));
                                            }}
                                            options={deliveryFrequencyResult}
                                        />
                                    </div>
                                )}
                            </div>
                            <div className="pt-10">
                                {products?.map((e: any) => (
                                    <BillingProductListingCard
                                        key={e.name}
                                        name={e.name}
                                        productAmount={e.quantity}
                                        productPrice={
                                            e.price === 0 ? undefined : e.price
                                        }
                                    />
                                ))}
                            </div>
                            <BillingDetailsCard
                                name={
                                    packageInfo?.parents?.[1] ||
                                    packageInfo?.fullName
                                }
                                price={packageInfo?.price}
                            />
                            {addOns?.length > 0 && (
                                <div>
                                    <div className="pt-2 text-base font-bold uppercase">
                                        Add-ons
                                    </div>
                                    <div
                                        role="separator"
                                        className="my-2 h-[3px] bg-secondary"
                                    />
                                    {addOns?.map((e: any) => (
                                        <BillingProductListingCard
                                            key={e.name}
                                            name={e.name}
                                            productAmount={e.quantity}
                                            productPrice={
                                                e.price === 0
                                                    ? undefined
                                                    : e.price
                                            }
                                        />
                                    ))}
                                    <BillingDetailsCard
                                        name="Subtotal"
                                        price={totalAddOnsPrice()}
                                    />
                                </div>
                            )}
                            {!partners && (
                                <div className="mt-6 text-base font-medium">
                                    Promo Code
                                    <PromoCodeInput
                                        data={discountData}
                                        simpleStyle
                                        discountCode={discountCode}
                                        setDiscountCode={setDiscountCode}
                                        error={discountError}
                                    />
                                    {promotion?.chooseItems > 0 &&
                                        promotion?.usable && (
                                            <div className="mt-2 text-base font-medium">
                                                <InputGroup
                                                    name={dot('choices')}
                                                >
                                                    <CheckoutSummaryPromotionCard
                                                        size={
                                                            promotion?.chooseItems
                                                        }
                                                        items={promotion?.items}
                                                        allItems={itemsById}
                                                        postcode={postcode}
                                                    />
                                                </InputGroup>
                                            </div>
                                        )}
                                </div>
                            )}
                            {!partners && (
                                <div className="mb-6 mt-4 text-base font-medium">
                                    Gift Card
                                    <GiftCardCodeInput simpleStyle />
                                    {giftCardsData.length > 0 && (
                                        <div className="mt-2.5">
                                            {giftCardsData
                                                .filter(
                                                    (
                                                        item: any,
                                                        index: any,
                                                        self: any
                                                    ) =>
                                                        index ===
                                                        self.findIndex(
                                                            (t: any) =>
                                                                t.code ===
                                                                item.code
                                                        )
                                                )
                                                .map((e: any) => {
                                                    if (e.user === user.id)
                                                        return (
                                                            <div
                                                                key={e.code}
                                                                className="mb-1.5 flex border border-solid border-primary bg-primary py-1.5 px-1.5"
                                                            >
                                                                <span className="my-auto mr-2.5 min-w-[70px] max-w-[70px] bg-white py-1 px-1 text-center text-xs font-bold">
                                                                    $
                                                                    {(
                                                                        e.value /
                                                                        100
                                                                    ).toFixed(
                                                                        2
                                                                    )}
                                                                </span>
                                                                <span className="my-auto text-xs font-normal text-white">
                                                                    {e.code}
                                                                </span>
                                                            </div>
                                                        );
                                                    return null;
                                                })}
                                        </div>
                                    )}
                                </div>
                            )}
                            <BillingDetailsCard
                                name="Subtotal"
                                price={totalProductPrice() + totalAddOnsPrice()}
                            />
                            {partners && (
                                <BillingDetailsCard
                                    name="Gift Card"
                                    price={partners.value}
                                    primary
                                />
                            )}
                            {promotion?.values?.length > 0 &&
                            promotion?.usable ? (
                                <BillingDetailsCard
                                    name="Discount"
                                    discount={getDiscountAmount(
                                        promotion?.values?.[0],
                                        totalProductPrice(),
                                        totalAddOnsPrice()
                                    )}
                                />
                            ) : (
                                <BillingDetailsCard name="Discount" price={0} />
                            )}
                            <BillingDetailsCard name="Shipping" price={0} />
                            {creditBalanceResult?.balance > 0 &&
                                getTotalPrice() > 0 && (
                                    <div className="grid grid-cols-2 gap-6">
                                        <span className="text-base font-medium uppercase">
                                            Credit
                                        </span>
                                        {creditBalanceResult?.balance >
                                        getTotalPrice() ? (
                                            <span className="text-end text-base font-bold">
                                                {formatDollarAmount(
                                                    getTotalPrice()
                                                )}
                                            </span>
                                        ) : (
                                            <span className="text-end text-base font-bold">
                                                {formatDollarAmount(
                                                    creditBalanceResult?.balance
                                                )}
                                            </span>
                                        )}
                                    </div>
                                )}
                            <div
                                role="separator"
                                className="my-4 h-[1px] bg-secondary"
                            />
                            {creditBalanceResult?.balance > 0 ? (
                                <div>
                                    {creditBalanceResult?.balance >
                                    getTotalPrice() ? (
                                        <BillingDetailsCard
                                            name="Total"
                                            price={0}
                                        />
                                    ) : (
                                        <BillingDetailsCard
                                            name="Total"
                                            price={
                                                getTotalPrice() -
                                                Number(
                                                    creditBalanceResult?.balance
                                                )
                                            }
                                        />
                                    )}
                                </div>
                            ) : (
                                <BillingDetailsCard
                                    name="Total"
                                    price={
                                        getTotalPrice() > 0
                                            ? getTotalPrice()
                                            : 0
                                    }
                                />
                            )}
                        </animated.div>
                    )}
                </Spring>
            </aside>
            {/* Desktop summary card */}
            <aside className="mb-12 mr-3.5 hidden w-1/5 min-w-[300px] flex-col border border-secondary bg-white p-4 shadow-block lg:flex">
                <h3 className="text-3xl font-bold uppercase">Your box</h3>
                <div role="separator" className="my-4 h-[5px] bg-secondary" />
                <div className="space-y-4">
                    <div>
                        <div className="text-[14px] font-medium">
                            {products?.length > 0
                                ? 'Custom box '
                                : 'Curated box '}
                            delivered on
                        </div>
                        {startedAt && (
                            <DashboardSelect
                                placeholder={shortDate
                                    .format(new Date(startedAt))
                                    .replace(',', '')}
                                onChange={() => {}}
                                disabled
                            />
                        )}
                    </div>
                    {deliveryFrequencyResult && (
                        <div>
                            <div className="text-[14px] font-medium">
                                Delivery Frequency
                            </div>
                            <DashboardSelect
                                value={`${frequency}`}
                                onChange={(value) =>
                                    setFrequency(Number(value))
                                }
                                options={deliveryFrequencyResult}
                            />
                        </div>
                    )}
                </div>
                <div className="max-h-[250px] overflow-auto pt-10">
                    {products?.map((e: any) => (
                        <BillingProductListingCard
                            key={e.name}
                            name={e.name}
                            productAmount={e.quantity}
                            productPrice={e.price === 0 ? undefined : e.price}
                        />
                    ))}
                </div>
                <BillingDetailsCard
                    name={packageInfo?.parents?.[1] || packageInfo?.fullName}
                    price={packageInfo?.price}
                />
                {addOns?.length > 0 && (
                    <div>
                        <div className="pt-2 text-base font-bold uppercase">
                            Add-ons
                        </div>
                        <div
                            role="separator"
                            className="my-2 h-[3px] bg-secondary"
                        />
                        <div className="max-h-[250px] overflow-auto">
                            {addOns?.map((e: any) => (
                                <BillingProductListingCard
                                    key={e.name}
                                    name={e.name}
                                    productAmount={e.quantity}
                                    productPrice={
                                        e.price === 0 ? undefined : e.price
                                    }
                                />
                            ))}
                        </div>
                        <BillingDetailsCard
                            name="Subtotal"
                            price={totalAddOnsPrice()}
                        />
                    </div>
                )}
                {!partners && (
                    <div className="mt-6 text-base font-medium">
                        Promo Code
                        <PromoCodeInput
                            data={discountData}
                            simpleStyle
                            discountCode={discountCode}
                            setDiscountCode={setDiscountCode}
                            error={discountError}
                        />
                        {promotion?.chooseItems > 0 && promotion?.usable && (
                            <div className="mt-2 text-base font-medium">
                                <InputGroup name={dot('choices')}>
                                    <CheckoutSummaryPromotionCard
                                        size={promotion?.chooseItems}
                                        items={promotion?.items}
                                        allItems={itemsById}
                                        postcode={postcode}
                                    />
                                </InputGroup>
                            </div>
                        )}
                    </div>
                )}
                {!partners && (
                    <div className="mb-6 mt-4 text-base font-medium">
                        Gift Card
                        <GiftCardCodeInput simpleStyle />
                        {giftCardsData?.length > 0 && (
                            <div className="mt-2.5">
                                {giftCardsData
                                    .filter(
                                        (item: any, index: any, self: any) =>
                                            index ===
                                            self.findIndex(
                                                (t: any) => t.code === item.code
                                            )
                                    )
                                    .map((e: any) => {
                                        if (e.user === user.id)
                                            return (
                                                <div
                                                    key={e.code}
                                                    className="mb-1.5 flex border border-solid border-primary bg-primary py-1.5 px-1.5"
                                                >
                                                    <span className="my-auto mr-2.5 min-w-[70px] max-w-[70px] bg-white py-1 px-1 text-center text-xs font-bold">
                                                        $
                                                        {(
                                                            e.value / 100
                                                        ).toFixed(2)}
                                                    </span>
                                                    <span className="my-auto text-xs font-normal text-white">
                                                        {e.code}
                                                    </span>
                                                </div>
                                            );
                                        return null;
                                    })}
                            </div>
                        )}
                    </div>
                )}
                <div className="space-y-2">
                    <BillingDetailsCard
                        name="Subtotal"
                        price={totalProductPrice() + totalAddOnsPrice()}
                    />
                    {partners && (
                        <BillingDetailsCard
                            name="Gift Card"
                            price={partners.value}
                            primary
                        />
                    )}
                    {promotion?.values?.length > 0 && promotion?.usable ? (
                        <BillingDetailsCard
                            name="Discount"
                            discount={getDiscountAmount(
                                promotion?.values?.[0],
                                totalProductPrice(),
                                totalAddOnsPrice()
                            )}
                        />
                    ) : (
                        <BillingDetailsCard name="Discount" price={0} />
                    )}
                    <BillingDetailsCard name="Shipping" price={0} />
                    {creditBalanceResult?.balance > 0 &&
                        getTotalPrice() > 0 && (
                            <div className="grid grid-cols-2 gap-6">
                                <span className="text-base font-medium uppercase">
                                    Credit
                                </span>
                                {creditBalanceResult?.balance >
                                getTotalPrice() ? (
                                    <span className="text-end text-base font-bold">
                                        {formatDollarAmount(getTotalPrice())}
                                    </span>
                                ) : (
                                    <span className="text-end text-base font-bold">
                                        {formatDollarAmount(
                                            creditBalanceResult?.balance
                                        )}
                                    </span>
                                )}
                            </div>
                        )}
                </div>
                <div role="separator" className="my-4 h-[1px] bg-secondary" />
                {creditBalanceResult?.balance > 0 ? (
                    <div>
                        {creditBalanceResult?.balance > getTotalPrice() ? (
                            <BillingDetailsCard name="Total" price={0} />
                        ) : (
                            <BillingDetailsCard
                                name="Total"
                                price={
                                    getTotalPrice() -
                                    Number(creditBalanceResult?.balance)
                                }
                            />
                        )}
                    </div>
                ) : (
                    <BillingDetailsCard
                        name="Total"
                        price={getTotalPrice() > 0 ? getTotalPrice() : 0}
                    />
                )}
            </aside>
        </>
    );
}
