import clsx from 'clsx';
import { Link, useParams } from 'react-router-dom';
import LinkButton from '@/components/common/LinkButton';
import Line from '@svg/line.svg';
import Navigation from '../navigation/Navigation';

const footerLinks = [
    {
        href: '/faq',
        label: 'HELP CENTRE',
    },
    {
        href: '/blog',
        label: 'BLOG',
    },
    {
        href: '/delivery',
        label: 'Delivery Zone',
    },
    {
        href: 'https://www.facebook.com/ButcherCrowd/',
        label: 'FACEBOOK',
    },
    {
        href: 'https://www.instagram.com/butcher_crowd/',
        label: 'INSTAGRAM',
    },
];

export default function WebFooter() {
    const { partners } = useParams();
    const PremiumQualityGuarantee = `${window.cdn}footer/premium-quality-guarantee-2.png`;
    const LogoWhite = `${window.cdn}logo-white.png`;

    return (
        <div className="relative bg-secondary pt-10">
            <img
                src={PremiumQualityGuarantee}
                alt="PREMIUM QUALITY GUARANTEE - CERTIFIED HUMANE RAISED & HANDLED - NEVER EVER BEEF PROGRAM"
                className="absolute top-5 right-1/2 max-h-[181px] translate-x-1/2 transform lg:right-16 lg:translate-x-0"
            />
            <div className="flex flex-col justify-center space-y-6 bg-primary py-14 pt-44 text-white md:py-20 md:px-52 md:pt-52  lg:space-y-11 lg:py-9">
                <img
                    src={LogoWhite}
                    alt="ButcherCrowd"
                    className="my-auto mx-auto max-h-[161px] lg:mx-auto"
                />
                <Navigation>
                    <ul className="flex list-none flex-col justify-center space-y-6 whitespace-nowrap text-center sm:flex-row sm:space-y-0 sm:space-x-8 md:space-x-11">
                        {footerLinks.map(({ href, label }, index) => (
                            <li key={label}>
                                <Navigation.Link
                                    // eslint-disable-next-line react/no-array-index-key
                                    key={index}
                                    asChild
                                >
                                    <LinkButton
                                        className="focus-visible:ring-secondary"
                                        href={href}
                                    >
                                        {label}
                                    </LinkButton>
                                </Navigation.Link>
                            </li>
                        ))}
                    </ul>
                </Navigation>
            </div>
            <div className="flex flex-col items-center justify-center bg-darkenPrimary py-4 sm:flex-row sm:space-x-8">
                <a
                    href="mailto:support@butchercrowd.com.au"
                    className="font-agrandir text-[10px] font-medium text-white transition-colors hover:text-surface hover:underline sm:text-xs"
                >
                    SUPPORT@BUTCHERCROWD.COM.AU
                </a>
                <a
                    href="tel:1300998999"
                    className="pt-2 font-agrandir text-[10px] font-medium text-white transition-colors hover:text-surface hover:underline sm:pt-0 sm:text-xs"
                >
                    P:1300 998 999
                </a>
            </div>
            <Navigation>
                <ul
                    className="
                        mx-auto
                        flex
                        max-w-[520px]
                        flex-wrap items-center
                        justify-center px-4 py-12 align-middle font-agrandir
                        text-xs font-bold
                        font-extrabold
                        text-gray-400
                        sm:max-w-[650px]
                        sm:justify-between sm:px-4 md:max-w-none md:py-8 md:px-4 lg:flex-nowrap lg:justify-center lg:space-x-6 lg:py-4 lg:text-sm"
                >
                    <li className="my-2 w-full  shrink-0 text-center text-[11px] sm:w-auto sm:text-[10px] md:my-0 lg:text-xs">
                        &copy; {new Date().getFullYear()} BUTCHERCROWD
                    </li>
                    <Line className="hidden lg:block" />
                    <li className="my-2 w-full shrink-0 text-center  text-[11px] sm:w-auto sm:text-[10px] md:my-0 lg:text-xs">
                        <Navigation.Link asChild>
                            <LinkButton asChild>
                                {partners ? (
                                    <a href="/terms">TERMS &amp; CONDITIONS</a>
                                ) : (
                                    <Link to="/terms">
                                        TERMS &amp; CONDITIONS
                                    </Link>
                                )}
                            </LinkButton>
                        </Navigation.Link>
                    </li>
                    <Line className="hidden lg:block" />
                    <li className="my-2 w-full shrink-0 text-center text-[11px] sm:w-auto sm:text-[10px] md:my-0 lg:text-xs">
                        <Navigation.Link asChild>
                            <LinkButton asChild>
                                {partners ? (
                                    <a href="/privacy-policy">
                                        {' '}
                                        PRIVACY POLICY
                                    </a>
                                ) : (
                                    <Link to="/privacy-policy">
                                        {' '}
                                        PRIVACY POLICY
                                    </Link>
                                )}
                            </LinkButton>
                        </Navigation.Link>
                    </li>
                    <Line className="hidden lg:block" />
                    <li className="my-2 w-full shrink-0 text-center text-[11px]  sm:w-auto sm:text-[10px] md:my-0 lg:text-xs">
                        <Navigation.Link asChild>
                            <LinkButton href="/refund-and-returns-policy">
                                Refund & Returns Policy
                            </LinkButton>
                        </Navigation.Link>
                    </li>
                    <li className="fixed">
                        <Navigation.Link asChild>
                            {({ focused }) => (
                                <a
                                    href="#main"
                                    className={clsx(
                                        'fixed bottom-4 right-4 bg-secondary px-5 py-3 font-neue text-sm font-medium uppercase tracking-wider text-white ring-2 ring-primary',
                                        !focused && 'hidden'
                                    )}
                                >
                                    Back to top
                                </a>
                            )}
                        </Navigation.Link>
                    </li>
                </ul>
            </Navigation>
        </div>
    );
}
